<template>
  <v-container class="pt-0">
    <back-toolbar title="activityhistory.header"></back-toolbar>
    <item-list
      :items="history"
      v-if="history && history.length > 0"
      :showSegmentedControl="false"
    ></item-list>
    <error-image
      v-else
      :text="$t('myPoco.noActivities')"
      imageName="noActivities.png"
    ></error-image>
  </v-container>
</template>
<script>
import ItemList from "@/components/recommendations/ItemList";
import BackToolbar from "@/components/app/common/BackToolbar";
import ErrorImage from "@/components/app/common/ErrorImage";
import orderBy from "lodash/orderBy";
import differenceBy from "lodash/differenceBy";
export default {
  components: {
    ItemList,
    BackToolbar,
    ErrorImage,
  },
  computed: {
    history() {
      // Detect past bookings and move them to history.
      let pastBookings = differenceBy(
        this.$store.state.profile.data.bookings,
        this.$store.getters["profile/futureBookings"],
        "visitId"
      );
      for (let i = 0; i < pastBookings.length; i++) {
        // Trying to prevent 'adding "null" to history' bug
        if (pastBookings[i]) {
          this.$store.commit(
            "profile/MOVE_TO_ACTIVITY_HISTORY",
            pastBookings[i]
          );
        }
      }
      // Save the changes in firebase.
      if (pastBookings.length > 0) {
        this.$store.dispatch("profile/updateProfile", {
          profile: {
            ...this.$store.state.profile.data,
          },
        });
      }
      return orderBy(
        this.$store.state.profile.data.history,
        ["arrival"],
        ["desc"]
      );
    },
  },
};
</script>
